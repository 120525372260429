<template>
  <div class="card">
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="headerCellStyle"
      tooltip-effect="light">
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        label="举报人联系方式"
        prop="contact">
      </el-table-column>
      <el-table-column
        label="被举报机构"
        prop="organizationName">
      </el-table-column>
      <el-table-column
        label="举报类型"
        prop="complaintType">
        <template slot-scope="scope">
          {{ scope.row.complaintType.toString() }}
        </template>
      </el-table-column>
      <el-table-column
        label="举报时间"
        prop="createdAt">
      </el-table-column>
      <el-table-column
        label="举报内容"
        prop="content">
        <template slot-scope="scope">
          <el-popover
            trigger="hover"
            placement="top">
            <p class="tw-p-10px tw-max-w-400px">
              {{ scope.row.content }}
            </p>
            <p
              slot="reference"
              class="tw-truncate">
              {{ scope.row.content }}
            </p>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="card_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      loading: false,
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    }
  },
  mounted () {
    this.getTableData(1)
  },
  methods: {
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    getTableData (pageNum) {
      this.loading = true
      api.getUserReportList({
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 980px;
  padding: 30px 40px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_search {
    margin-bottom: 20px;
  }
  &_pagination {
    margin-top: 20px;
    height: 40px;
  }
}
</style>
